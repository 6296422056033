<template>
  <div class="">
    <h2>
      商标监测
    </h2>
    <!-- 表单 -->
    <el-tabs v-model="replace.monitortype" @tab-click="tabClk">
      <el-tab-pane v-for="item in tabs" :name="item.type">
      <span slot="label">
         {{ item.type }}
        <span class="tab_num font-12 col-fff line-h20">{{ item.count }}</span>
      </span>
      </el-tab-pane>

    </el-tabs>
    <form ref="filters" action="" v-on:submit.prevent>

      <el-row :class="{on:formOpen}" class="input formInput">
        <el-col :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>商标名称</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-input
                  v-model="filters.tmname"
                  clearable
                  placeholder="请输入商标名称"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>商标号</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-input
                  v-model="filters.tmcode"
                  clearable
                  placeholder="请输入商标号"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>

        <el-col v-if="['驳回待复审','无效待答辩','异议待答辩','撤三待答辩'].indexOf(replace.monitortype) != -1"
                :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>发文距今</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.ago"
                  clearable
                  filterable
                  placeholder="请选择发文距今"
                  size="small"
              >
                <el-option
                    v-for="item in ((['驳回待复审'].indexOf(replace.monitortype) != -1) ? agoSelect  : agoOpenSelect)"
                    :key="item.val"
                    :label="item.text"
                    :value="item.val"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="['可续展商标'].indexOf(replace.monitortype) != -1" :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>续展期限</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.range"
                  clearable
                  filterable
                  placeholder="请选择续展期限"
                  size="small"
              >
                <el-option
                    v-for="item in rangeSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="['可续展商标','被侵权风险','可异议商标'].indexOf(replace.monitortype) != -1" 
        :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>法律状态</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.tmlaw"
                  clearable
                  filterable
                  placeholder="请选择法律状态"
                  size="small"
              >
                <el-option
                    v-for="item in tmlawSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="['可变更商标'].indexOf(replace.monitortype) != -1" :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>变更类型</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.type"

                  clearable
                  filterable
                  placeholder="请选择变更类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="['商标公告'].indexOf(replace.monitortype) != -1" :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>公告类型</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.type"
                  clearable
                  filterable
                  placeholder="请选择公告类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="['商标公告'].indexOf(replace.monitortype) != -1" :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>公告期号</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.ggqh"
                  clearable
                  filterable
                  placeholder="请选择公告期号"
                  size="small"
              >
                <el-option
                    v-for="item in ggqhSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="['被无效风险','被撤三风险'].indexOf(replace.monitortype) != -1" :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>注册已满</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.over"
                  clearable
                  filterable
                  placeholder="请选择注册已满"
                  size="small"
              >
                <el-option
                    v-for="item in overSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>商标类别</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.intcls"
                  clearable
                  filterable
                  placeholder="请选择商标类别"
                  size="small"
              >
                <el-option
                    v-for="item in intclsSelect"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>申请人</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.appname"
                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="5" :md="5" :sm="6" :xl="5" :xs="19">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="7" :md="9" :sm="9" :xl="5" :xs="6"><strong>代理机构</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="19" :xs="18">
              <el-select
                  v-model="filters.agentname"
                  clearable
                  filterable
                  placeholder="请选择代理机构"
                  size="small"
              >
                <el-option
                    v-for="item in agentnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :xs="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary"
                 @click="perindexList(filters.page = 1)"
      >查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset"
      >重置
      </el-button
      >
    </form>

    <div class="mainrb">

      <div class="listT clearfix">
        <el-button :disabled="domesticList.length == 0 || loading" :loading="down" icon="el-icon-document" size="small"
                   type="primary" @click="download">
          {{ down ? '导出中' : '导出数据' }}
        </el-button>
        <el-checkbox
            v-if="['商标公告'].indexOf(replace.monitortype) == -1"
            :disabled="loading"
                     class="m-l10" v-model="replace.noexpire"
                     @change="replace.noexpire ? (filters.noexpire = 1) : (filters.noexpire = 0),perindexList()">
          过滤已失效
        </el-checkbox>
      </div>

      <!-- 块状列表 -->
      <div :class="{empty:domesticList.length == 0,on:!formOpen,ios:$store.state.heights}" class="list-list">
        <ul class="list_t flex align-cen ju-con-cen">

          <li>
            <span class="pointer"
                  @click="sort == '' ? (filters.sort = 'appdate|asc',sort = 'appdateAsc') : (sort == 'appdateAsc' ? (filters.sort = 'appdate|desc',sort = 'appdateDes') : ((sort.indexOf('appdate') == -1) ?  (filters.sort = 'appdate|asc',sort = 'appdateAsc') : (filters.sort = '',sort = '')) ),perindexList() ">申请日期</span>
            <span class="caret-wrapper">
              <i :class="{on:sort == 'appdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'appdateAsc' ? (sort = '',filters.sort = '') : (sort = 'appdateAsc',filters.sort = 'appdate|asc'),perindexList() "></i>
              <i :class="{on:sort == 'appdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'appdateDes' ? (sort = '',filters.sort = '') : (sort = 'appdateDes',filters.sort = 'appdate|desc'),perindexList() "></i>
            </span>
          </li>
          <li v-if="['驳回待复审','可续展商标','被驳回风险','被侵权风险'].indexOf(replace.monitortype) == -1 ">
            <span class="pointer"
                  @click="sort == '' ? (filters.sort = 'csdate|asc',sort = 'csdateAsc') : (sort == 'csdateAsc' ? (filters.sort = 'csdate|desc',sort = 'csdateDes') : ((sort.indexOf('csdate') == -1) ?  (filters.sort = 'csdate|asc',sort = 'csdateAsc') : (filters.sort = '',sort = ''))),perindexList() ">初审日期</span>
            <span class="caret-wrapper">
              <i :class="{on:sort == 'csdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'csdateAsc' ? (sort = '',filters.sort = '') : (sort = 'csdateAsc',filters.sort = 'csdate|asc'),perindexList()  "></i>
              <i :class="{on:sort == 'csdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'csdateDes' ? (sort = '',filters.sort = '') : (sort = 'csdateDes',filters.sort = 'csdate|desc') ,perindexList() "></i>
            </span>
          </li>
          <li v-if="['驳回待复审','被驳回风险','被侵权风险'].indexOf(replace.monitortype) == -1 ">
            <span class="pointer"
                  @click="sort == '' ? (filters.sort = 'regdate|asc',sort = 'regdateAsc') : (sort == 'regdateAsc' ? (filters.sort = 'regdate|desc',sort = 'regdateDes') : ((sort.indexOf('regdate') == -1) ?  (filters.sort = 'regdate|asc',sort = 'regdateAsc') : (filters.sort = '',sort = ''))),perindexList() ">注册日期</span>
            <span class="caret-wrapper">
              <i :class="{on:sort == 'regdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'regdateAsc' ? (sort = '',filters.sort = '') : (sort = 'regdateAsc',filters.sort = 'regdate|asc'),perindexList()  "></i>
              <i :class="{on:sort == 'regdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'regdateDes' ? (sort = '',filters.sort = '') : (sort = 'regdateDes',filters.sort = 'regdate|desc') ,perindexList() "></i>
            </span>
          </li>
          <li v-if="['驳回待复审','无效待答辩','异议待答辩','撤三待答辩'].indexOf(replace.monitortype) != -1 ">
            <span class="pointer"
                  @click="sort == '' ? (filters.sort = 'fwdate|asc',sort = 'fwdateAsc') : (sort == 'fwdateAsc' ? (filters.sort = 'fwdate|desc',sort = 'fwdateDes') : ((sort.indexOf('fwdate') == -1) ?  (filters.sort = 'fwdate|asc',sort = 'fwdateAsc') : (filters.sort = '',sort = ''))),perindexList() ">发文日期</span>
            <span class="caret-wrapper">
              <i :class="{on:sort == 'fwdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'fwdateAsc' ? (sort = '',filters.sort = '') : (sort = 'fwdateAsc',filters.sort = 'fwdate|asc'),perindexList()  "></i>
              <i :class="{on:sort == 'fwdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'fwdateDes' ? (sort = '',filters.sort = '') : (sort = 'fwdateDes',filters.sort = 'fwdate|desc') ,perindexList() "></i>
            </span>
          </li>
          <li v-if="['可续展商标'].indexOf(replace.monitortype) != -1 ">
            <span class="pointer"
                  @click="sort == '' ? (filters.sort = 'enddate|asc',sort = 'enddateAsc') : (sort == 'enddateAsc' ? (filters.sort = 'enddate|desc',sort = 'enddateDes') : ((sort.indexOf('enddate') == -1) ?  (filters.sort = 'enddate|asc',sort = 'enddateAsc') : (filters.sort = '',sort = ''))),perindexList() ">专用期限</span>
            <span class="caret-wrapper">
              <i :class="{on:sort == 'enddateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'enddateAsc' ? (sort = '',filters.sort = '') : (sort = 'enddateAsc',filters.sort = 'enddate|asc'),perindexList()  "></i>
              <i :class="{on:sort == 'enddateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'enddateDes' ? (sort = '',filters.sort = '') : (sort = 'enddateDes',filters.sort = 'enddate|desc') ,perindexList() "></i>
            </span>
          </li>
        </ul>
        <el-empty v-if="domesticList.length == 0" :image-size="80"></el-empty>
        <div v-else class="list-cont">
          <el-skeleton :class="{on:!formOpen,ios:$store.state.heights}" :cont="1" :loading="loading" animated>
            <template slot="template">
              <div v-for="(item, index) in loadList" :key="index" class="listb">
                <div class="content">
                  <div class="">
                    <el-skeleton-item
                        style="width: 110px;height:110px"
                        variant="image"
                    />
                  </div>
                  <div class="contentC">
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>

                    </p>
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                    <p>
                      <el-skeleton-item style="margin-bottom: 0" variant="text"/>
                    </p>
                  </div>
                  <div class="details">
                    <el-skeleton-item
                        style="width: 100px"
                        variant="p"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div v-for="(item,index) in domesticList" class="listb bor-bottom">
              <div class="content">
                <div class="content-index">
                  {{ index + 1 }}
                </div>
                <div class="content-img ">
                  <div class="img-img  flex align-cen ju-con-cen">
                    <el-image
                        :preview-src-list="[item.tmimage]"
                        :src="item.tmimage">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div
                      :class="{tag9:['行政诉讼撤诉','续展不予核准','其它状态'].indexOf(item.tmlaw) != -1,tag4:['商标无效','无效宣告'].indexOf(item.tmlaw) != -1,'tag-primary':['撤销','驳回复审完成','异议程序完成','异议程序完成','撤销复审完成','撤三程序完成','无效宣告完成','撤回异议完成'].indexOf(item.tmlaw) != -1,tag5:['撤三不予受理','无效不予受理','异议不予受理'].indexOf(item.tmlaw) != -1,'tag2':['初审公告','初步审定'].indexOf(item.tmlaw) != -1,tag7:['已注册','准予注册','维持注册'].indexOf(item.tmlaw) != -1,tag1:['驳回待复审','不予注册','驳回'].indexOf(item.tmlaw) != -1,tag6:['注册申请中','无效程序中','异议程序中','撤三程序中','撤销复审中','驳回复审中','商标续展中','行政诉讼中'].indexOf(item.tmlaw) != -1}"
                      class="img-tmlaw ">
                    {{ item.tmlaw }}
                  </div>
                </div>
                <div class="contentC flex flex-direction flex-gap15 col-606">
                  <div class="flex flex-cen flex-gap5">
                    <span class="flex-item font-16 col-blue ellipse">
                       <el-tooltip v-showtip :content="item.clstext + '：' + item.tmname" class="item" effect="dark"
                                   placement="top-start">
                         <span class="w100 disinblk ellipse">
                           <font :class="{'col-blue':item.tmname }" class="ellipse-text ellipse w60">
                             <strong class="col-606">{{ item.clstext || '-' }}：</strong>
                                {{ item.tmname || '-' }}
                           </font>
                         </span>
                      </el-tooltip>

                    </span>
                    <span class="flex-item flex align-cen ">

                      <font class="ellipse max-w75 disinblk">商标号：{{ item.tmcode || '-' }}</font>
                      <i v-copy="item.tmcode" class="el-icon-copy-document col-blue pointer m-l5"></i>
                    </span>
                    <span v-if="['可异议商标','被驳回风险','被侵权风险'].indexOf(replace.monitortype) != -1"
                          class="flex-item ellipse">申请日期：{{ item.appdate || '-' }}</span>
                    <span v-if="['商标公告','可变更商标'].indexOf(replace.monitortype) != -1" class="col-E6A flex-item ellipse">{{
                        item.type || '-'
                      }}</span>
                    <span v-if="['驳回待复审','无效待答辩','异议待答辩','撤三待答辩'].indexOf(replace.monitortype) != -1"
                          class="col-E6A flex-item ellipse">{{
                        (replace.monitortype == '驳回待复审') ? '驳回' : '受通'
                      }}发文距今{{
                        Math.ceil((new Date().getTime() - new Date(item.fwdate).getTime()) / (24 * 60 * 60 * 1000))
                      }}天</span>
                    <span v-if="['可续展商标'].indexOf(replace.monitortype) != -1" class="col-E6A flex-item ellipse">
                      {{ (Math.ceil((new Date().getTime() - new Date(item.enddate).getTime()) / (24 * 60 * 60 * 1000))) > 0 ? '超出' : '' }}专用期限{{ (Math.ceil((new Date().getTime() - new Date(item.enddate).getTime()) / (24 * 60 * 60 * 1000))) < 0 ? '截止剩余' : '' }}{{
                        Math.ceil((new Date().getTime() - new Date(item.enddate).getTime()) / (24 * 60 * 60 * 1000)) > 0 ? Math.ceil((new Date().getTime() - new Date(item.enddate).getTime()) / (24 * 60 * 60 * 1000)) : Math.ceil((new Date(item.enddate).getTime() - new Date().getTime() ) / (24 * 60 * 60 * 1000))
                      }}天</span>
                    <span v-if="['被无效风险','被撤三风险'].indexOf(replace.monitortype) != -1" class="col-red flex-item ellipse">注册{{
                        Math.floor((new Date().getTime() - new Date(item.regdate).getTime()) / (24 * 60 * 60 * 1000 * 365)) < 1 ? '不足1' : ('已满' + Math.floor((new Date().getTime() - new Date(item.regdate).getTime()) / (24 * 60 * 60 * 1000 * 365)))
                      }}年</span>
                  </div>
                  <div v-if="['可异议商标','被驳回风险','被侵权风险'].indexOf(replace.monitortype) == -1"
                       class="flex flex-cen flex-gap5">
                    <p class="flex-item ellipse">申请日期：{{ item.appdate || '-' }}</p>
                    <p v-if="['异议待答辩','无效待答辩','撤三待答辩','可续展商标','可变更商标','被无效风险','被撤三风险'].indexOf(replace.monitortype) != -1"
                       class="flex-item ellipse">初审日期：{{ item.csdate || '-' }}</p>
                    <p v-if="['可变更商标'].indexOf(replace.monitortype) != -1"
                       class="flex-item ellipse">注册日期：{{ item.regdate || '-' }}</p>
                    <p v-if="['驳回待复审','无效待答辩','异议待答辩','撤三待答辩'].indexOf(replace.monitortype) != -1"
                       class="flex-item ellipse"> {{
                        ['撤三待答辩', '无效待答辩'].indexOf(replace.monitortype) != -1 ? '受通' : ''
                      }}发文日期：{{ item.fwdate || '-' }}</p>
                    <p v-if="['商标公告'].indexOf(replace.monitortype) != -1" class="flex-item ellipse">公告期号：{{
                        item.ggqh || '-'
                      }}</p>
                    <p v-if="['商标公告'].indexOf(replace.monitortype) != -1"
                       class="flex-item ellipse">公告日期：{{ item.ggdate || '-' }}</p>
                  </div>
                  <div
                      v-if="['商标公告','无效待答辩','撤三待答辩','可续展商标','可异议商标','被侵权风险','被无效风险','被撤三风险'].indexOf(replace.monitortype) != -1"
                      class="flex flex-cen flex-gap5">
                    <p v-if="['商标公告','可异议商标','被侵权风险'].indexOf(replace.monitortype) != -1"
                       class="flex-item ellipse">初审日期：{{
                        item.csdate || '-'
                      }}</p>
                    <p v-if="['商标公告','无效待答辩','撤三待答辩','可异议商标','被侵权风险','被无效风险','被撤三风险','可续展商标'].indexOf(replace.monitortype) != -1"
                       class="flex-item ellipse">注册日期：{{ item.regdate || '-' }}</p>
                    <span
                        v-if="['商标公告','无效待答辩','撤三待答辩','可续展商标','可异议商标','被侵权风险','被无效风险','被撤三风险'].indexOf(replace.monitortype) != -1"
                        :class="{'col-red':['可续展商标'].indexOf(replace.monitortype) != -1}"
                        class="flex-item ellipse">专用期限至：{{ item.enddate || '-' }}</span>
                  </div>

                  <div class="flex flex-cen appname flex-gap5">
                    <p class="flex-item ellipse flex align-cen">
                      申请人：
                      <el-tooltip v-showtip :content="item.appname" class="item" effect="dark" placement="top-start">
                         <span class=" ellipse">
                           <font :class="{'col-blue':item.appname }"
                                 class="ellipse-text ellipse w60"> {{ item.appname || '-' }} </font>
                         </span>
                      </el-tooltip>
                    </p>
                    <p class="flex-item  ellipse flex align-cen">代理机构：
                      <el-tooltip v-showtip :content="item.agentname" class="item" effect="dark" placement="top-start">
                         <span class=" ellipse">
                           <font :class="{'col-blue':item.agentname }"
                                 class="ellipse-text ellipse w60"> {{ item.agentname || '-' }} </font>
                         </span>
                      </el-tooltip>


                    </p>

                    <span class="flex-item " v-if="['商标公告'].indexOf(replace.monitortype) == -1">
                      处理状态：<el-tag :type="item.state == 1 ? 'success' : (item.state == 0 ?  'danger' : 'info')" size="small">
                        {{ item.state == 1 ? '已处理' : (item.state == 0 ?  '未处理' : '已忽略') }}
                      </el-tag>
                    </span>
                  </div>
                  <div v-if="['可变更商标'].indexOf(replace.monitortype) != -1" class="flex flex-cen flex-gap5 appname">
                    <p v-if="item.type == '地址变更'" class="w50">申请人地址：
                      <el-tooltip v-showtip :content="item.appaddr" class="item" effect="dark" placement="top-start">
                         <span class=" ellipse">
                           <font :class="{'col-blue':item.appaddr }"
                                 class="ellipse-text ellipse w60"> {{ item.appaddr || '-' }} </font>
                         </span>
                      </el-tooltip>
                    </p>
                    <p :class="{w100:item.type != '地址变更',w50:item.type == '地址变更'}" class=" col-red">
                      现工商{{ item.type == '地址变更' ? '地址' : '名称' }}：
                      <el-tooltip v-showtip :content="(item.type == '地址变更' ?  item.appaddrnow : item.appnamenow )" class="item"
                                  effect="dark"
                                  placement="top-start">
                         <span class=" ellipse">
                           <font class="ellipse-text ellipse w60"> {{
                               item.type == '地址变更' ? item.appaddrnow : item.appnamenow
                             }}</font>
                         </span>
                      </el-tooltip>
                    </p>

                  </div>
                  <div v-if="['驳回待复审','异议待答辩','可异议商标','被驳回风险','被侵权风险'].indexOf(replace.monitortype) != -1"
                       class="pointer disblk"
                       @click="approxi(item.id)">

                    <el-tooltip v-showtip :content="similarArr[index]" class="item" effect="dark" placement="top-start">
                         <span class="ellipsis w100 disinblk">
                           <font class="ellipse-text col-blue"><font
                               class="col-303">近似商标：</font> {{ similarArr[index] || '-' }} </font>
                         </span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="details  disblk">
                  <el-button class="m-b10" @click="details(item)" icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
                  <div class="" v-if="['商标公告'].indexOf(replace.monitortype) == -1">
                    <el-button class="m0" v-if="item.state == 0 && item.expire == 0" type="text" size="small" @click="handle.id = item.id,handleDia = true,handleLoad = false">
                      处理
                    </el-button>
                    <div class="" v-else>
                      <span class="col-red m-r10" v-if="item.expire == 1">已失效</span>
                      <el-button class="m-r10" v-else type="text" size="small" @click="handle.id = item.id,handle.action = 3,reprocess()">
                        重新处理
                      </el-button>

                      <el-button  type="text" size="small" @click="logData = item.log, logDia = true">
                        操作日志
                      </el-button>
                    </div>
                  </div>


                </div>
              </div>
              <div v-if="item.similar"
                   :ref="`similarRef${index}`"
                   :class="{on:approximate.indexOf(item.id) != -1}"
                   class="approximate col-606  flex flex-gap10 flex-warp">
                <div v-for="similar in item.similar" class="ba-col-fff bor-rad5 approximate_item grid grid-row-gap10">
                  <p class="item_title col-303  over_hid">
                    <el-tooltip v-showtip :content="similar.tmname" class="item" effect="dark" placement="top-start">
                         <span class=" ellipse w100 disinblk">
                           <font class="ellipse-text ellipse  w60"> {{ similar.tmname || '-' }}</font>
                         </span>
                    </el-tooltip>
                  </p>
                  <div class="item_content flex flex-gap10">
                    <div class="content_img border1">
                      <div class="img flex align-cen ju-con-cen">
                        <el-image
                            :preview-src-list="[similar.tmimage]"
                            :src="similar.tmimage">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline font-50"></i>
                          </div>
                        </el-image>
                      </div>
                      <div class="img_num text-cen font-12">
                        <span class="w25 disinblk ">{{ similar.intcls || '-' }}</span>
                        <router-link
                            :to="{name: 'tradDetails',params: { tmcode: similar.tmcode, intcls: similar.intcls,alias:0},}"
                            class="w75 disinblk col-blue"
                            target="_blank">
                          {{ similar.tmcode || '-' }}
                        </router-link>
                      </div>
                    </div>
                    <div class="content_text grid grid-row-gap8 font-12 w100">
                      <p v-if="['可异议商标','被驳回风险','被侵权风险'].indexOf(replace.monitortype) != -1" :class="{'col-00A':['一般近似'].indexOf(similar.level) != -1,'col-D81':['高度近似','名称相同'].indexOf(similar.level)  != -1 }"
                         class="ellipse">
                        {{ similar.level || '-' }}</p>
                      <p
                          :class="{'col-E6A':['注册申请中','无效程序中','异议程序中','撤三程序中','撤销复审中','驳回复审中','商标续展中','行政诉讼中'].indexOf(similar.tmlaw) != -1,'col-green':['初审公告','初步审定'].indexOf(similar.tmlaw) != -1,'col-00A': ['已注册','准予注册','维持注册'].indexOf(similar.tmlaw) != -1,'blue':['撤销','驳回复审完成','异议程序完成','异议程序完成','撤销复审完成','撤三程序完成','无效宣告完成','撤回异议完成'].indexOf(similar.tmlaw) != -1,'col-605':['撤三不予受理','无效不予受理','异议不予受理','部分撤销','部分无效'].indexOf(similar.tmlaw) != -1,'col-909':['商标无效','无效宣告'].indexOf(similar.tmlaw) != -1,'col-red':['驳回待复审','不予注册','驳回'].indexOf(similar.tmlaw) != -1,'col-111':['行政诉讼撤诉','续展不予核准','其它状态'].indexOf(similar.tmlaw) != -1}"
                         class="ellipse">
                        {{ similar.tmlaw || '-' }}</p>
                      <p class="col-blue w100 over_hid">
                        <el-tooltip v-showtip :content="similar.appname" class="item" effect="dark"
                                    placement="top-start">
                         <span class=" ellipse w100 disinblk">
                           <font class="ellipse-text ellipse  w60"> {{ similar.appname || '-' }}</font>
                         </span>
                        </el-tooltip>
                      </p>

                      <p class="">申请日期：{{ similar.appdate || '-' }}</p>
                      <p class="">初审日期：{{ similar.csdate || '-' }}</p>
                      <p v-if="['驳回待复审','异议待答辩'].indexOf(replace.monitortype) != -1" class="">
                        注册日期：{{ similar.regdate || '-' }}</p>
                    </div>
                  </div>
                  <p v-if="['可异议商标'].indexOf(replace.monitortype) != -1" class=" col-E6A font-12 w100 over_hid">
                    <el-tooltip v-showtip :content="similar.left" class="item" effect="dark" placement="top-start">
                         <span class=" ellipse w100 disinblk">
                           <font class="ellipse-text ellipse  w60"> {{ similar.left || '-' }}</font>
                         </span>
                    </el-tooltip>
                  </p>
                </div>

              </div>
            </div>

          </el-skeleton>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList(backTop)"
      >
      </el-pagination>
    </div>

    <el-dialog title="监测处理" :visible.sync="handleDia" width="474px" class="handleDia" :closeOnClickModal="false" @close="closeDia">
      <el-form :model="handle" :rules="handleRules" ref="handleRef">
        <el-form-item label="操作" prop="action">
          <el-radio-group v-model="handle.action">
            <el-radio :label="1">已处理</el-radio>
            <el-radio :label="2">已忽略</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark" >
          <el-input type="textarea" placeholder="请输入内容" v-model="handle.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDia = false" size="small">取 消</el-button>
        <el-button :loading="handleLoad"  type="primary" size="small" @click="handleSave('handleRef')">确 定</el-button>
      </div>
    </el-dialog>


      <el-dialog title="操作日志" class="logDia" :visible.sync="logDia" width="640px" :closeOnClickModal="false">

      <el-table :data="logData" height="100%">
        <el-table-column type="index" label="#" width="50"></el-table-column>
        <el-table-column property="actor" label="操作人" ></el-table-column>
        <el-table-column property="action" label="操作" ></el-table-column>
        <el-table-column property="remark" label="备注" width="200"></el-table-column>
        <el-table-column property="date" label="时间" width="170"></el-table-column>
      </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="logDia = false" size="small">关 闭</el-button>
        </div>
    </el-dialog>
  </div>

</template>

<script>
import storage from '@/utils/storage'
import {tmMonitor, tmMonitorCount,tmMonitorExport,tmMonitorProcess} from '@/api/personal.js'

export default {
  name: "risk",
  data() {
    return {
      loading: true,//控制骨架屏的显示与隐藏
      logData:[],//日志数据
      logDia:false,//控制日志对话框的显示与隐藏
      handleDia:false,//控制处理对话框的显示与隐藏
      handleLoad:false,//控制保存处理按钮的加载状态
      handle:{
        id:'',
        action:1,
        remark:'',
      },//处理字段
      handleRules:{
        action: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        remark: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },//处理验证规则
      down: false,//控制导出加载的显示与隐藏

      approximate: [],//记录近似商标的id
      similarArr: [],//将近似商标转成字符串并放在一起
      tabs: [
        {type: '商标公告', count: 0},
        {type: '驳回待复审', count: 0},
        {type: '无效待答辩', count: 0},
        {type: '异议待答辩', count: 0},
        {type: '撤三待答辩', count: 0},
        {type: '可续展商标', count: 0},
        {type: '可变更商标', count: 0},
        {type: '可异议商标', count: 0},
        {type: '被驳回风险', count: 0},
        {type: '被侵权风险', count: 0},
        {type: '被无效风险', count: 0},
        {type: '被撤三风险', count: 0},
      ],//头部切换初始化
      domesticList: [],//；列表数据
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      formOpen: false,//控制搜索栏展开或收起
      replace: {
        monitortype: '',//检测类型
        noexpire:true,
      },//替换字段

      filters: {
        tmcode:'',
        monitortype: '',//检测类型
        type: '',//公告类型
        tmname: "", //商标名称
        intcls: '',//商标类别
        ggqh: '',//公告期号
        appname: '',//申请人
        agentname: '',//代理机构
        sort: '',//排序
        ago: '',//发文距今
        range: '',//续展期限
        over: '',//注册已满
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        noexpire:1,//失效
      },//搜索字段
      last_page: 1,//当前页初始化
      pageSize: 0, //总条数
      sort: '',//记录排序
      //商标类别，下拉框的所需内容
      intclsSelect: [],
      typeSelect: [],//变更类型下拉框的所需内容
      overSelect: ['注册已满4年', '注册已满3年', '注册已满2年', '注册已满1年', '注册不足1年'],//注册已满,下拉框的所需内容
      appnameSelect: [],//申请人，下拉框的所需内容
      agentnameSelect: [],//申请人代理机构，下拉框的所需内容
      tmlawSelect: [],//法律状态，下拉框的所需内容
      agoSelect: [
        {text: '7天内', val: '0-7'}, {text: '8-14天', val: '8-14'}, {text: '15-21天', val: '15-21'}, {
          text: '22-30天',
          val: '22-30'
        }
      ],//发文距今下拉框的所需内容
      agoOpenSelect: [
        {text: '15天内', val: '0-15'}, {text: '15-30天', val: '15-30'}, {text: '30-45天', val: '30-45'}
      ],// 驳回发文距今下拉框的所需内容
      rangeSelect: [
        '可续展前一年', '可续展', '可续展前六个月', '可续展前三个月', '可续展前一个月', '可宽展'
      ],// 续展下拉框的所需内容
      ggqhSelect: [],//公告期号下拉框的所需内容


    }
  },
  created() {
    this.typeCont()
  },
  methods: {
    details(item){
    let routeData =  this.$router.resolve({ name: 'tradDetails',params: { tmcode: item.tmcode, intcls: item.intcls,alias:0 }})
      window.open(routeData.href, '_blank');
    },

    // 重新处理弹框
    reprocess(){
      this.$confirm('此操作将重置当前处理状态为“未处理”，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        tmMonitorProcess(this.handle).then(res =>{
          this.handle = this.$options.data().handle;
          this.perindexList()
        })
      }).catch(() => {
        this.handle = this.$options.data().handle;
      });
    },
    closeDia(){
      this.handle = this.$options.data().handle;
      this.$refs.handleRef.resetFields()
    },
    // 保存处理
    handleSave(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleLoad = true
          tmMonitorProcess(this.handle).then(res =>{
            this.handleDia = false
            this.perindexList()
            this.closeDia()
          })
        } else {
          return false;
        }
      });
    },

    // 确认导出
    async download() {
      this.down = true
      try {
        const res = await tmMonitorExport(this.filters)
        if (res && res.err_code === 0) {
          location.href = res.data.url
          this.down = false
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }

      } finally {
        this.down = false
      }

    },

    // 类型统计
    typeCont() {
      tmMonitorCount().then(res => {
        this.tabs.forEach(item => {
          res.data.forEach(tab => {
            if (item.type == tab.type) {
              item.count = tab.count

            }
          })
        })
        this.filters.monitortype = this.replace.monitortype = this.tabs[0].type

        this.perindexList()
      })
    },

    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList(this.backTop);
    },
    // tab切换
    tabClk(tab, event) {
      this.filters = this.$options.data().filters;
      this.sort = ''
      this.filters.monitortype = this.replace.monitortype
      this.replace.noexpire = true
      this.approximate = []
      this.perindexList()
    },
    //渲染列表事件
   async  perindexList(backTop) {

      this.loading = true;
      let res = await tmMonitor(this.filters)
          try{
            if (res && res.err_code === 0) {
              if (backTop === true) {
                storage.backTop()
              }
              this.loading = false
              this.domesticList = res.data.list
              this.intclsSelect = res.data.filter.intcls
              this.agentnameSelect = res.data.filter.agentname
              this.typeSelect = res.data.filter.type
              this.appnameSelect = res.data.filter.appname
              this.ggqhSelect = res.data.filter.ggqh
              this.tmlawSelect = res.data.filter.tmlaw
              this.similarArr = []
              this.domesticList.forEach((item, index) => {
                if (index == 0) {
                  this.approximate.push(item.id)
                }
                if (item.similar) {
                  let str = ''
                  item.similar.forEach((similar, similarInx) => {
                    str += similar.tmname + ((similarInx == item.similar.length - 1) ? '' : '、')
                  })
                  this.similarArr.push(str)
                }
              })
              this.pageSize = res.data.total
              this.tabs.forEach((item) =>{
                if(this.replace.monitortype == item.type){
                  item.count = this.pageSize
                }
              })
            } else {
              this.domesticList = []
            }
          }finally{
            this.loading = false
          }


    },
//重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.sort = ''
      this.filters.monitortype = this.replace.monitortype
      this.perindexList()
    },
    approxi(id) {
      this.approximate.indexOf(id) == -1 ? this.approximate.push(id) : this.approximate.splice(this.approximate.indexOf(id), 1)
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../style/domestic.less";
::v-deep .logDia{
  .el-dialog__body{
    padding: 20px;
  }
  .el-table{
    .el-table__cell{
      text-align: left;
    }
    th,td{
      color: #303133 ;

    }
    th{
      background: unset;

      font-weight: bold;
    }
    .el-table__body-wrapper{
      height: unset !important;
      max-height: 420px !important;
    }
  }

}
::v-deep .handleDia{
  .el-dialog__body{
    padding: 20px;
    .el-form-item:nth-last-of-type(1){
      margin: 0;
    }
    .el-form-item__label{
      padding: 0;
    }
    .el-form-item__content{
      margin-left: 50px;
    }
  }

}
::v-deep .el-tabs {
  background: #fff;

  .el-tabs__header {
    padding: 0 15px;
  }

  .tabs__header {
    margin: 0 !important;
  }

  .el-tabs__item {
    .tab_num {
      padding: 0 6.5px;
      border-radius: 24px;
      background: #F56C6C;
    }
  }

}

.contentC > div {
  margin: 0 !important;

  p {
    line-height: unset !important;
    display: block !important;

  }

}

.contentC .appname {
  p {
    display: flex !important;
    justify-content: unset !important;

    span {
      width: 60% !important;
      font-size: 14px !important;
      font-weight: normal !important;
    }
  }

  p.col-red {
    color: #f56c6c !important;
  }
}

.approximate {
  height: 0px;
  padding: 0 15px;
  overflow: hidden;
  //transition: all 0.5s;
  background: #F4F4F4;

  .approximate_item {
    padding: 12px;
    width: 270px;

    .item_title {
      line-height: 17px;
      width: 245px;
    }

    .item_content {
      .content_img {
        width: 100px;

        .img {
          height: 98px;
          width: 98px;

          ::v-deep img {
            max-width: 98px;
            max-height: 98px;
          }
        }

        .img_num {
          border-top: 1px solid #EBEEF5;

          span {
            line-height: 24px;
          }

          .w25 {
            border-right: 1px solid #EBEEF5;
          }
        }

      }

      .content_text {
        .over_hid {
          width: 130px;
        }
      }
    }
  }
}

.approximate.on {
  height: 100%;
  padding: 15px;

}

.approximate::-webkit-scrollbar {
  width: 5px;
  height: 15px;

}

.details{
  line-height: unset;
  justify-content:unset;
  .el-button--text{
    text-align: left;
  }
}
@media screen and (min-width: 768px) {
  .details{
    width: 15%;
  }
  .contentC{
    width: 66%;
  }
  .formInput.on {
    height: 126px;
  }

  .list-list > div > div {
    height: calc(100vh - 529px);
  }

  .list-list > div > div.on {
    height: calc(100vh - 445px);
  }

  .list-list.empty.on {
    height: calc(100vh - 404px);
  }

  .list-list.empty {
    height: calc(100vh - 488px);
  }

  .list-list > div > div.on.ios {
    height: calc(100vh - 486px);
  }

  .list-list > div > div.ios {
    height: calc(100vh - 570px);
  }
}

@media screen and (max-width: 768px) {
  .details{
    width: 17%;
  }
  .contentC{
    width: 64%;
  }
  .formInput.on {
    height: 284px;
  }

  .list-list > div > div {
    height: calc(100vh - 697px);
  }

  .list-list > div > div.on {
    height: calc(100vh - 445px);
  }

  .list-list.empty.on {
    height: calc(100vh - 404px);
  }

  .list-list.empty {
    height: calc(100vh - 646px);
  }

  .list-list > div > div.on.ios {
    height: calc(100vh - 486px);
  }

}

@media screen and (min-width: 992px) {
  .el-col-md-3 {
    width: 12.914%;
  }

  .el-col-md-21 {
    width: 87.086%;
  }

  .formInput.on {
    height: 74px;
  }

  .list-list > div > div {
    height: calc(100vh - 477px);
  }

  .list-list.empty {
    height: calc(100vh - 436px);
  }

  .list-list > div > div.ios {
    height: calc(100vh - 518px);
  }
}

@media screen and (min-width: 1200px) {
  .details{
    width: 12%;
  }
  .contentC{
    width: 70%;
  }
  .el-col-lg-3 {
    width: 9.476%;
  }

  .el-col-lg-21 {
    width: 90.524%;
  }
}

@media screen and (min-width: 1920px) {
  .el-col-xl-2 {
    width: 5.376%;
  }

  .el-col-xl-22 {
    width: 94.624%;
  }
}
</style>