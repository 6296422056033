<template>
<div class="p-b40 atlas-detail" :class="{ios:$store.state.heights}">
    <div class="">
      <h2>
        商标图谱
      </h2>
      <div class="atlas p15 col-606 ba-col-fff grid grid-row-gap15">
        <div class="flex align-cen flex-gap12">
          <span class="font-wbold">申请人名称：</span>
          <el-select v-model="filters.appname" @change="initChart" placeholder="请选择申请人" size="mini">
            <el-option v-for="item in appnameSelect" :key="item.id" :label="item.text" :value="item.text">
            </el-option>
          </el-select>
        </div>


        <div class="num flex flex-cen flex-gap15">
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">总量</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>

            <p v-else class="col-blue font-22">
              {{ summary.total || '0' }}
            </p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">已注册</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22">{{ summary.total_reg || '0' }}</p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">申请中</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22">{{ summary.total_app || '0' }}</p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">核心商标</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22 ellipsis">
              <span v-for="(item, index) in summary.core_tm">{{ item }}<font v-if="index != summary.core_tm.length - 1">、
                </font> </span>
            </p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">核心类别</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22 ellipsis">
              <span v-for="(item, index) in summary.core_cls">{{ item }}<font v-if="index != summary.core_tm.length - 1">、
                </font> </span>
            </p>
          </div>
        </div>
      </div>

    </div>
    <div class="">
      <h2 class="font-18">
        商标布局
      </h2>
      <div class="incls p15 ba-col-fff flex flex-warp flex-gap15 w100">
        <div class="flex align-cen flex-gap15 col-606 over_hid">
          <div class="flex align-cen flex-gap12">
            <span class="font-wbold">商标名称：</span>
            <el-input v-model="filters.tmname" clearable placeholder="请输入商标名称，多个商标以“，”相隔" size="mini"
              @clear="tmnameChange">
              <i @click="tmnameChange" class="el-icon-search el-input__icon" slot="suffix">
              </i>
            </el-input>
          </div>
          <div class="flex align-cen flex-gap10">
            <span class="font-wbold">法律状态：</span>
            <div class="flex align-cen flex-gap10 col-303">
              <span class="pointer"
                @click="state = item.id, filters.tmlaw = [], filters.tmlaw = item.array, tmnameChange()"
                v-for="item in stateSelect" :class="{ 'col-blue': state == item.id, 'font-wbold': state == item.id }">
                {{ item.text }}
              </span>
            </div>
          </div>
        </div>
        <el-skeleton :cont="1" :loading="inclsLoading" animated>
          <template slot="template">
            <el-table :data="loadList" border class="over_hid" height="435" style="width: 100%">
              <el-table-column label="图样" width="80">
                <template>
                  <el-skeleton-item class="w80" variant="p" />
                </template>
              </el-table-column>
              <el-table-column label="名称" width="80">
                <template>
                  <el-skeleton-item class="w80" variant="p" />
                </template>
              </el-table-column>
              <el-table-column label="类别">
                <template>
                  <el-skeleton-item class="w80" variant="p" />
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-table :data="tmDist" border height="590" class="incls_tab" style="width: 100%">
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column fixed label="图样" width="80">
              <template slot-scope="scope">
                <el-image :src="scope.row.tmimage" :preview-src-list="[scope.row.tmimage]">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline font-30"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column fixed label="名称"  width="80">
              <el-tooltip slot-scope="scope" class="item" effect="dark" v-showtip :content="scope.row.tmname" placement="top-start">
                <font class=" ellipsis w80 disinblk">
                  <font class="ellipse-text ">
                    {{ scope.row.tmname || '图形' }}
                  </font>
                </font>
              </el-tooltip>
            </el-table-column>
            <el-table-column label="类别" :width="alitWidth ? 1200 : ''">
              <template slot-scope="scope">
                <div class="flex flex-cen ">
                  <span v-for="item in 45"
                    :class="{ on: scope.row.clsdist.includes((item < 10) ? ('0' + item) : item.toString()) }"
                    class="flex-item disblk h100">
                    {{ (item < 10) ? ('0' + item) : item }} </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>

      </div>
    </div>
    <div class="">
      <h2 class="font-18">
        商标申请量
      </h2>
      <div class="applyNum ba-col-fff">
        <div class="num_tabs flex align-cen flex-gap10">
          <span class="font-wbold col-606 ">申请年份</span>
          <el-tabs v-model="filters.appdate" @tab-click="appdateEcharts">
            <el-tab-pane label="全部" name="全部"></el-tab-pane>
            <el-tab-pane :label="(new Date().getFullYear() - index).toString()"
              :name="(new Date().getFullYear() - index).toString()" v-for="(item, index) in 5"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="num_earch p15">
          <div id="apply_echarts"></div>
        </div>

      </div>
    </div>
    <div class=" bar">
      <div class=" ">
        <h2 class="font-18">
          类别分布
        </h2>
        <div class="ba-col-fff">
          <div class="bar_select ">
            <div class="flex  flex-gap10 align-cen ">
              <div class="flex align-cen  flex-gap12 col-606">
                <span class="font-wbold">商标名称：</span>
                <el-input v-model="filters.intname" clearable placeholder="请输入商标名称，多个商标以“，”相隔" size="mini"
                  @clear="inclsEcharts">
                  <i @click="inclsEcharts" class="el-icon-search el-input__icon" slot="suffix">
                  </i>
                </el-input>
              </div>
              <div class="flex align-cen  flex-gap15">
                <span class=" font-wbold">法律状态：</span>
                <span class="pointer" @click="filters.type = item.id, tmlaw = [],tmlaw = item.array, inclsEcharts()"
                  v-for="item in stateSelect"
                  :class="{ 'col-blue': filters.type == item.id, 'font-wbold': filters.type == item.id }">{{ item.text }}</span>

              </div>

            </div>
          </div>
          <div class="p15 flex flex-warp  flex-gap15">
            <div class="p8 col-blue flex align-cen ju-con-around flex-gap5 bor-rad5"
              v-for="(item, index) in incls_barSelect"
              :class="{ num0: item.value === 0, num20: (item.value <= 20 && item.value != 0), num100: (item.value <= 100 && item.value - 20 > 0), nummore: (item.value > 100 && item.value != 0) }">
              <i :class="item.icon" class=" iconfont"></i>
              <div class="tc ">
                <p class="m-b5">{{ item.text.replace('类 -', '') }}</p>
                <p>{{ item.value }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class=" ">
        <h2 class="font-18">
          状态统计
        </h2>
        <div class="ba-col-fff">
          <div class="bar_select">
            <div class="flex  flex-gap10 align-cen ">
              <div class="flex align-cen  flex-gap15 col-606">
                <span class="font-wbold">商标名称：</span>
                <el-input v-model="filters.typname" clearable placeholder="请输入商标名称，多个商标以“，”相隔" size="mini"
                  @clear="tmLawEcharts">
                  <i @click="tmLawEcharts" class="el-icon-search el-input__icon" slot="suffix">
                  </i>
                </el-input>
              </div>
              <div class="flex align-cen  flex-gap15">
                <span class=" font-wbold">商标类别：</span>
                <el-select v-model="filters.intcls" placeholder="请选择商标类别" size="mini" @change="tmLawEcharts">
                  <el-option v-for="item in intclsSelect" :key="item.id" :label="item.text" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="p15">
            <div style="height: 360px;width: 100%" id="type_bar"></div>
          </div>
        </div>

      </div>
    </div>

    <div class="three">
      <h2 class="font-18">注册满三年</h2>
      <el-row type="flex" align="middle" justify="space-between" :gutter="15" class="ba-col-fff p15 m0">
        <el-col :span="18">
          <div id="three_pie"></div>
        </el-col>
        <el-col :span="6">
          <div class="three_more plf15 grid grid-row-gap15 col-000">
            <p class="col-303">相关商标</p>
            <el-skeleton :cont="1" :loading="loading" animated>
              <template slot="template">
                <div class="grid grid-row-gap15">
                  <el-skeleton-item variant="text" v-if="index < 9" v-for="(item, index) in loadList"
                    style="height: 20px" />
                </div>
              </template>
              <template>
                <el-empty v-if="threeList.length == 0" description="暂无数据"></el-empty>
                <div v-else class="grid grid-row-gap15 w100 ">
                  <p class="flex flex-cen w100 over_hid flex-gap15" v-for="(item, index) in threeList">
                    <span class="font-12 col-fff disinblk text-cen">{{ index + 1 }}</span>
                    <span class=" ellipsis ">{{ item.name }}</span>
                    <span>{{ item.value }}</span>
                  </p>
                </div>

              </template>


            </el-skeleton>
            <router-link v-if="!loading" :to="{
              name: 'domestic',
              params: { overthree: 1 }
            }" class="text-cen col-909">
              查看全部 >>
            </router-link>

          </div>
        </el-col>
      </el-row>

    </div>

    <div class="trend">
      <h2 class="font-18">申请趋势</h2>
      <div class="p15 ba-col-fff">
        <div id="trend_line"></div>
      </div>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import { tmChartsSummary, tmChartsTmDist, tmChartsTmApplies, tmChartsIntCls, tmChartsTmLaw, tmChartsOverThree, tmChartsOverThreeTms, tmChartsOverAppTrend } from '@/api/personal.js'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, PieChart, LineChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  ToolboxComponent,
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'

// 注册必须的组件
echarts.use([
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LineChart
]);
export default {
  data() {
    return {
      alitWidth: false,
      incls_barSelect: storage.getInicon(),
      intclsSelect: storage.getIntcls(),
      filters: {
        appname: storage.getUser().company,
        tmname: '',
        intname: '',
        typname: '',
        tmlaw: ["申请中", "已注册"],
        appdate: '全部',
        intcls: '',
        noinvalid: 1,
        year: 0,
        type: 0,

      },
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      applyEmpty: false,//商标申请量数据为空时
      numLoading: true,
      loading: true,//控制骨架屏的显示与隐藏
      inclsLoading: true,
      threeList: [],//满三年相关商标
      checked: true,//过滤无效商标
      tmlaw: ["申请中", "已注册"],//类别分布的法律状态
      stateSelect: [
        { id: 0, text: '申请中+已注册', array: ["申请中", "已注册"] },
        { id: 1, text: '申请中', array: ["申请中"] },
        { id: 2, text: '已注册', array: ["已注册"] },
      ],//法律状态
      state: 0,//当前法律状态
      appnameSelect: [],//申请人下拉
      summary: {},//商标统计
      tmDist: [],//商标分布
      applyDate: {},//商标申请量图表数据

    }
  },
  mounted() {
    if (document.body.offsetWidth < 1200) {
      this.alitWidth = true
    } else {
      this.alitWidth = false
    }
    window.onresize = () => {
      if (document.body.offsetWidth < 1200) {
        this.alitWidth = true
      } else {
        this.alitWidth = false
      }

    }
    this.select();
    this.initChart()

  },
  methods: {

    async initChart() {
      this.numLoading = true
      try {
        const res = await tmChartsSummary({ appname: this.filters.appname })
        if (res && res.err_code === 0) {
          this.numLoading = false
          this.summary = res.data
          this.tmnameChange()
          this.inclsEcharts()
          this.tmLawEcharts()
          this.appdateEcharts()


          this.threeEcharts()

          this.overThree()

          this.trendEcharts()
        }
      } finally {

      }

    },

    // 商标布局
    async tmnameChange() {
      this.inclsLoading = true

      let tmname = this.filters.tmname ? this.filters.tmname.split(/，|;|；|,/) : []
      try {

        const res = await tmChartsTmDist({ appname: this.filters.appname, tmlaw: this.filters.tmlaw, tmname: tmname })
        if (res && res.err_code === 0) {
          this.inclsLoading = false
          this.tmDist = res.data
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {

      }
    },
    //商标申请量
    async appdateEcharts() {
      let appdate = (this.filters.appdate == '全部') ? null : this.filters.appdate
      let applyEcharts = echarts.init(document.getElementById('apply_echarts'));
      applyEcharts.showLoading()
      try {
        const res = await tmChartsTmApplies({ appname: this.filters.appname, appdate: appdate })
        if (res && res.err_code === 0) {

          let name = []
          let apps = []
          let regs = []
          for (let i in res.data) {
            name.push(res.data[i].name)
            apps.push(res.data[i].apps)
            regs.push(res.data[i].regs)
          }

          applyEcharts.setOption(this.applyOption(name, apps, regs))


        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        applyEcharts.hideLoading()
      }
      window.addEventListener("resize", () => {
        applyEcharts.resize();

      });
    },
    //商标申请量图表
    applyOption(...item) {
      let width = '';
      let empty = '';
      if (item[0].length == 0) {
        empty = '暂无数据'
      } else if (item[0].length < 10) {
        width = '20%'
      }
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['申请量', '注册量'],
          right: 0,
        },
        grid: {
          left: '1%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,//横轴信息全部显示
              rotate: -20,//-15度角倾斜显示
              overflow: 'truncate',
              width: 50,
            },
            type: 'category',
            axisTick: { show: false },
            data: item[0]
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        title: {
          subtext: empty,
          x: "center",
          y: "center",
          subtextStyle: {
            fontSize: 20,
          },

        },
        series: [
          {
            name: '申请量',
            type: 'bar',
            barGap: 0,
            barWidth: width,
            barMaxWidth: width,
            data: item[1]
          },
          {
            name: '注册量',
            type: 'bar',
            barWidth: width,
            barMaxWidth: width,
            data: item[2]
          },
        ]
      };
      return option

    },

    //类别分布
    async inclsEcharts() {
     
      let tmname = this.filters.intname ? this.filters.intname.split(/，|;|；|,/) : []
      try {
        const res = await tmChartsIntCls({ appname: this.filters.appname, tmlaw: this.tmlaw, tmname: tmname })
        if (res && res.err_code === 0) {
          if(res.data){
            res.data.forEach(item => {
            this.incls_barSelect.forEach(item1 => {
              if (item.name == item1.text) {
                item1.value = item.value
              }
            })
          })
          }else{
            this.incls_barSelect.forEach(item1 => {
              item1.value = 0
            })
          }
         


        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {

      }
    },


    //状态分布
    async tmLawEcharts() {
      let typeBar = echarts.init(document.getElementById('type_bar'));
      typeBar.showLoading()
      let tmname = this.filters.typname ? this.filters.typname.split(/，|;|；|,/) : []
      try {
        const res = await tmChartsTmLaw({ appname: this.filters.appname, noinvalid: this.filters.noinvalid, tmname: tmname, intcls: this.filters.intcls })
        if (res && res.err_code === 0) {
          let name = []
          let value = []
          res.data.forEach(item => {
            name.push(item.name)
            value.push(item.value)
          })

          typeBar.setOption(this.typeBar(name, value))

        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        typeBar.hideLoading()
      }
      window.addEventListener("resize", () => {
        typeBar.resize()
      });
    },
    //状态分布图表
    typeBar(...item) {
      let empty = '';
      if (item[0].length == 0) {
        empty = '暂无数据'
      }
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          subtext: empty,
          x: "center",
          y: "center",
          subtextStyle: {
            fontSize: 20,
          },

        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,//横轴信息全部显示
              rotate: -20,//-15度角倾斜显示
              overflow: 'truncate',
            },
            type: 'category',
            data: item[0],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            itemStyle: {
              color: '#409EFF'
            },
            name: '数量',
            type: 'bar',
            barWidth: '40%',
            label: {
              show: true,
              position: 'top'
            },
            data: item[1]
          }
        ]
      };
    },
    //注册满三年
    async threeEcharts() {
      let threePie = echarts.init(document.getElementById('three_pie'));
      threePie.showLoading()
      try {
        const res = await tmChartsOverThree({ appname: this.filters.appname })
        if (res && res.err_code === 0) {
          let name = []
          let value = []
          res.data.forEach(item => {
            name.push(item.name)
            value.push(item.value)
          })

          threePie.setOption(this.threePie(name, value))

          threePie.on('click', params => {
            this.threeList = []
            this.filters.year = params.dataIndex

            this.overThree()
          })
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        threePie.hideLoading()
      }
      window.addEventListener("resize", () => {
        threePie.resize()
      });
    },
    //注册满三年图表
    threePie(...item) {
      let empty = '';
      if (item[0].length == 0) {
        empty = '暂无数据'
      }
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          subtext: empty,
          x: "center",
          y: "center",
          subtextStyle: {
            fontSize: 20,
          },

        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {

            type: 'category',
            data: item[0],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            itemStyle: {
              color: '#409EFF'
            },
            name: '数量',
            type: 'bar',
            barWidth: '40%',
            data: item[1]
          }
        ]
      };
    },
    //相关商标
    async overThree() {
      this.loading = true
      try {
        const res = await tmChartsOverThreeTms({ appname: this.filters.appname, year: this.filters.year })
        if (res && res.err_code === 0) {
          this.loading = false
          this.threeList = res.data
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {

      }
    },
    //申请趋势
    async trendEcharts() {
      let trendLine = echarts.init(document.getElementById('trend_line'));
      trendLine.showLoading()
      try {
        const res = await tmChartsOverAppTrend({ appname: this.filters.appname })
        if (res && res.err_code === 0) {
          let name = []
          let apps = []
          let regs = []
          res.data.forEach(item => {
            name.push(item.name)
            apps.push(item.apps)
            regs.push(item.regs)
          })

          trendLine.setOption(this.trendLine(name, apps, regs))
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }

      } finally {
        trendLine.hideLoading()
      }
      window.addEventListener("resize", () => {
        trendLine.resize()
      });
    },
    //申请趋势图表
    trendLine(...item) {
      const colors = ['#409EFF', '#14C9C9'];
      let empty = '';
      if (item[0].length == 0) {
        empty = '暂无数据'
      }
      return {
        color: colors,
        tooltip: {
          trigger: 'axis'
        },
        title: {
          subtext: empty,
          x: "center",
          y: "center",
          subtextStyle: {
            fontSize: 20,
          },

        },
        legend: {
          right: 0,
          
        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: item[0]
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [

          {
            name: '注册量',
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: item[2]
          },
          {
            name: '申请量',
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: item[1]
          },
        ]
      };
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('app_names')
      this.appnameSelect = s.app_names
    },



  }
}
</script>
<style lang="less" scoped>
h2 {
  margin-top: 10px !important;
}

.atlas {
  ::v-deep .el-input {
    width: 250px;
  }

  .num {
    .item {
      flex: 1;
      padding: 12px 24px;
      border-radius: 12px;
    }
  }
}

.incls {
  ::v-deep .el-input {
    width: 400px;

    .el-input__suffix-inner {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  ::v-deep .incls_tab {
    overflow: hidden;

    td {
      padding: 0;

      .cell {
        padding: 0;

        .flex {
          height: 80px;



          .flex-item {
            border-right: 1px solid #E4E7ED;
            color: #409EFF;
            line-height: 80px;
          }

          .flex-item.on {
            border-right: 1px solid #FFFFFF;
            color: #FFFFFF;
            background: #409EFF;
          }

          .flex-item:nth-last-of-type(1) {
            border: none;
          }
        }

        .el-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 79px;

          img {
            width: unset;
            height: unset;
            max-width: 75px !important;
            max-height: 75px !important;
          }

        }
      }

    }
  }
}

.incls>div:nth-last-of-type(1) {
  width: 100%;

}

.applyNum {
  .num_tabs {
    padding: 15px 0px 0px 15px;

    ::v-deep .el-tabs {

      .el-tabs__header {
        margin: 0;

        .el-tabs__nav-wrap::after,
        .el-tabs__active-bar {
          height: 0;
        }

        .el-tabs__item {
          height: unset;
          font-weight: 400;
          padding: 0 5px;
          line-height: unset;
        }

        .el-tabs__item.is-active {
          font-weight: bold;
        }
      }
    }
  }

  .num_earch {
    #apply_echarts {
      height: 390px;
    }
  }
}

.bar {
  
  .bar_select {
    padding: 15px 15px 0px;

    ::v-deep .el-input,
    .el-select {
      width: 400px;
      .el-input__suffix-inner {
      display: flex;
      flex-direction: row-reverse;
    }
    }
  }

  .p15>div {
    min-width: 173px;

    i {

      font-size: 32px;
    }

  }

  .p15>div.num0 {
    background: var(--neutral-color-white, #FFF);
    opacity: 0.2;
  }

  .p15>div.num20 {
    background: var(--primary-100, #ECF5FF);

  }

  .p15>div.num100 {
    background: #BCDEFF;

  }

  .p15>div.nummore {
    background: var(--primary-300, #409EFF);
    color: #fff;

  }

  .p15>div.num20:hover,
  .p15>div.num20:hover,
  .p15>div.num100:hover,
  .p15>div.nummore:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }

  ::v-deep .el-checkbox {
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #606266;
    }
  }
}

.three {
  #three_pie {
    height: 390px;
  }

  .three_more {
    p {
      span.disinblk {
        width: 20px;
        height: 20px;
        line-height: 20px;
        background: #FF4D4F;
        border-radius: 10px;
      }

      span:nth-of-type(2) {
        width: calc(100% - 80px);
      }
    }
  }
}

.trend {
  #trend_line {
    height: 390px;
  }
}
</style>