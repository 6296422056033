<template>
  <div class="applyDetail ">

    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <h2>账单信息</h2>
        <div class="applyDetail-top ba-col-fff line-h30 col-606">
          <div class="flex flex-cen">
            <p>
              <strong>申请人名称：</strong>
              <el-skeleton-item style="width: 50%" variant="p"/>
            </p>
            <p>
              <strong>申请人地址：</strong> <el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>
          <div class="flex flex-cen">
            <p><strong>总金额：</strong>  <el-skeleton-item style="width: 50%" variant="p"/></p>
            <p>
              <strong>付款方式：</strong>
              <el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>
          <div class="flex flex-cen">
            <p>
              <strong>付款状态：</strong>
              <el-skeleton-item style="width: 50%" variant="p"/>

            </p>
            <p>
              <strong>付款时间：</strong>
              <el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>
        </div>
        <h2>票据信息</h2>
        <div class="applyDetail-top ba-col-fff line-h30 col-606">
          <div class="flex flex-cen">
            <p>
              <strong>票据类型：</strong>
              <el-skeleton-item style="width: 50%" variant="p"/>
            </p>
            <p>
              <strong>开票状态：</strong>
              <el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>
          <div class="flex flex-cen">
            <p>
              <strong>抬头：</strong><el-skeleton-item style="width: 50%" variant="p"/>
            </p>
            <p>
              <strong>税号：</strong><el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>
          <div class="flex flex-cen">
            <p>
              <strong>地址：</strong><el-skeleton-item style="width: 50%" variant="p"/>
            </p>
            <p>
              <strong>电话：</strong><el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>
          <div class="flex flex-cen">
            <p>
              <strong>开户银行：</strong><el-skeleton-item style="width: 50%" variant="p"/>
            </p>
            <p>
              <strong>银行账号：</strong><el-skeleton-item style="width: 50%" variant="p"/>
            </p>
          </div>

        </div>
        <div class="applicationDetails-list">
          <h2>申请事宜</h2>
          <div class="flex flex-cen list-t">
            <h3 class="pointer"><i class="el-icon-arrow-up"></i>商标事宜</h3>

          </div>

          <el-table
              ref="multipleTable"
              :data="skeletonList"
              style="width: 100%"
              tooltip-effect="dark"
          >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column label="#" type="index" width="50">

            </el-table-column>
            <el-table-column label="商标号">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标名称">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标类别">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="申请类型">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="报送日期">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
          </el-table>
          <div class="flex flex-cen list-t">
            <h3 class="pointer"><i class="el-icon-arrow-up"></i>版权事宜</h3>

          </div>
          <el-table
              ref="multipleTable"
              :data="skeletonList"
              style="width: 100%"
              tooltip-effect="dark"
          >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column label="#" type="index" width="50">

            </el-table-column>
            <el-table-column label="登记号">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="作品名称">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="作品类型">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="申请类型">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="报送日期">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
          </el-table>

          <div class="flex flex-cen list-t">
            <h3 class="pointer"><i class="el-icon-arrow-up"></i>诉讼事宜</h3>

          </div>
          <el-table
              ref="multipleTable"
              :data="skeletonList"
              style="width: 100%"
              tooltip-effect="dark"
          >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column label="#" type="index" width="50">

            </el-table-column>
            <el-table-column label="商标号">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标名称">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标类别">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="申请类型">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="原告/上诉人">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template>
        <h2>账单信息</h2>
        <div class="applyDetail-top ba-col-fff line-h30 col-606">
          <div class="flex ju-con-bet">
            <p>
              <strong>申请人名称：</strong>
              <router-link v-if="detail.client_id != 0" :to="{
                  name: 'newlyBuild',
                    params: { id:  detail.client_id  },
                }" target="_blank" class="col-blue">
                {{detail.appname }}
              </router-link>
              <template v-else>
                {{ detail.appname }}
              </template>
            </p>
            <p>
              <strong>申请人地址：</strong> {{ detail.appnaddr || '-' }}
            </p>
          </div>
          <div class="flex ju-con-bet">
          <p><strong>总金额：</strong>  ￥{{ detail.subtotal }}</p>
            <p>
              <strong>付款方式：</strong>
              {{
                detail.money_status != 1 ? '-' : (detail.channel == 1 ? '微信扫码' : (detail.channel == 2 ? '支付宝扫码' : '线下汇款'))
              }}
            </p>
          </div>
          <div class="flex ju-con-bet">
            <p>
              <strong>付款状态：</strong>
              <span :class="{'col-green':detail.money_status == 1,'col-red':detail.money_status == 0}">
                {{ detail.money_status == 0 ? '未付款' : (detail.money_status == 1 ? '已付款' : '部分付款') }}
              </span>

            </p>
            <p>
              <strong>付款时间：</strong>
              {{ detail.money_status != 1 ? '-' : detail.money_at }}
            </p>
          </div>
        </div>

        <h2>票据信息</h2>
        <div class="applyDetail-top ba-col-fff">
          <div class="m-b10 line-h30 col-606">
            <div class="flex ju-con-bet">
            <p>
              <strong>票据类型：</strong>
              {{ invoice.type || '-' }}
            </p>
            <p>
              <strong>开票状态：</strong>
              <span :class="{'col-E6A':detail.invoice_status == 0,'col-green':detail.invoice_status == 1}">
                {{ (detail.invoice_status == 1 ? '已开票' : '未开票') }}
              </span>

            </p>
            </div>
            <div class="flex ju-con-bet">
            <p>
              <strong>抬头：</strong>
              <span :class="{'col-blue':detail.invoice_status == 1 && invoice.client}">{{(detail.invoice_status == 1 && invoice.client) ? invoice.client : '-' }}</span>
            </p>
            <p>
              <strong>税号：</strong>
              {{(detail.invoice_status == 1 && invoice.tax_number) ? invoice.tax_number : '-' }}
            </p>
            </div>
            <div class="flex ju-con-bet">
            <p>
              <strong>地址：</strong>
              {{(detail.invoice_status == 1 && invoice.address) ?  invoice.address : '-' }}
            </p>
            <p>
              <strong>电话：</strong>
              {{(detail.invoice_status == 1 && invoice.phone) ?  invoice.phone : '-' }}
            </p>
            </div>
            <div class="flex ju-con-bet">
            <p>
              <strong>开户银行：</strong>
              {{(detail.invoice_status == 1 && invoice.bank) ?  invoice.bank : '-' }}
            </p>
            <p>
              <strong>银行账号：</strong>
              {{(detail.invoice_status == 1 && invoice.bank_account) ?  invoice.bank_account : '-' }}
            </p>
            </div>

          </div>
          <el-table :data="invoice.digit" v-if="invoice.digit.length != 0">
            <el-table-column label="#" type="index" width="50">
            </el-table-column>
            <el-table-column label="发票号" prop="number"></el-table-column>
            <!-- <el-table-column label="金额" prop="amount">
              <span slot-scope="scope" >
                ￥{{ scope.row.amount || 0 }}
              </span>
            </el-table-column> -->
            <el-table-column label="上传时间" prop="made_at"></el-table-column>
            <el-table-column  label="操作">
              <el-button  slot-scope="scope" v-if="scope.row.url" type="text" size="small" @click="openUrl(scope.row)" icon="el-icon-download">下载</el-button>
              <span v-else>-</span>
            </el-table-column>
          </el-table>
        </div>
        

        <div class="applicationDetails-list">
          <h2>申请事宜</h2>
          <div v-if="tms.length != 0" class="">
            <div class="flex flex-cen list-t">
              <h3 class="pointer" @click="trademark = !trademark"><i
                  :class="{  'el-icon-arrow-up' : trademark, 'el-icon-arrow-down':!trademark}"></i>商标事宜
                <span>{{ tms.length }}</span></h3>
              <el-button :disabled="tms.length == 0" :loading="down && (type == 'trademark')" icon="el-icon-document"
                         size="small" type="primary" @click="diaClick('trademark')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"
                :class="{on:!trademark}"
                :data="tms"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="商标号" prop="tmcode">

              </el-table-column>
              <el-table-column label="商标名称">
                <template slot-scope="scope">
                  <div class="tmimage flex align-cen">
                    <div class="">
                      <el-image
                          :preview-src-list="[scope.row.tmimage]"
                          :src="scope.row.tmimage"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <span>{{ scope.row.tmname || '图形' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商标类别" prop="intcls" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="申请类型" prop="apptype" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.subtotal }}
                </template>
              </el-table-column>
              <el-table-column label="报送日期" prop="deliver_at"></el-table-column>
            </el-table>
          </div>
          <div v-if="copyrights.length != 0" class="">
            <div class="list-t flex flex-cen">
              <h3 class="pointer" @click="copyright = !copyright"><i
                  :class="{  'el-icon-arrow-up' : copyright, 'el-icon-arrow-down':!copyright}"></i>版权事宜
                <span>{{ copyrights.length }}</span></h3>
              <el-button :disabled="copyrights.length == 0" :loading="down && (type == 'copyright')"
                         icon="el-icon-document" size="small" type="primary" @click="diaClick('copyright')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"

                :class="{on:!copyright}"
                :data="copyrights"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="登记号">
                <template slot-scope="scope">
                  {{ scope.row.register_number || '图形' }}
                </template>
              </el-table-column>
              <el-table-column label="作品名称">
                <template slot-scope="scope">
                  <div class="tmimage flex align-cen">
                    <div class="">
                      <el-image

                          :preview-src-list="[scope.row.picture]"
                          :src="scope.row.picture"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <span>{{ scope.row.name || '图形' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="作品类型" :show-overflow-tooltip="true" prop="type"></el-table-column>
              <el-table-column label="申请类型" :show-overflow-tooltip="true" prop="apptype"></el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.subtotal }}
                </template>
              </el-table-column>
              <el-table-column label="报送日期" prop="deliver_at"></el-table-column>
            </el-table>
          </div>
          <div v-if="lawsuits.length != 0" class="">
            <div class="list-t flex flex-cen">
              <h3 class="pointer" @click="lawsuit = !lawsuit"><i
                  :class="{  'el-icon-arrow-up' : lawsuit, 'el-icon-arrow-down':!lawsuit}"></i>诉讼事宜
                <span>{{ lawsuits.length }}</span></h3>
              <el-button :disabled="lawsuits.length == 0" :loading="down && (type == 'lawsuit')" icon="el-icon-document"
                         size="small" type="primary" @click="diaClick('lawsuit')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"
                :class="{on:!lawsuit}"
                :data="lawsuits"
                class="mb20"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="商标号">
                <template slot-scope="scope">
                  {{ scope.row.tmcode || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="商标名称">
                <template slot-scope="scope">
                  <div class="tmimage flex align-cen">
                    <div class="">
                      <el-image
                          :preview-src-list="[scope.row.tmimage]"
                          :src="scope.row.tmimage"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <span>{{ scope.row.tmname || '图形' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商标类别" :show-overflow-tooltip="true" prop="intcls"></el-table-column>
              <el-table-column label="申请类型" :show-overflow-tooltip="true" prop="apptype"></el-table-column>
              <el-table-column label="原告/上诉人" :show-overflow-tooltip="true" prop="applicant"></el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.subtotal }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" prop="created_at"></el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </el-skeleton>

    <el-dialog
        :closeOnClickModal="false"
        :show-close="false"
        :visible.sync="commandDia"
        width="810px">
      <div slot="title" class="">
        <i class="el-icon-error fr pointer" @click="commandDia = false"></i>
      </div>
      <div class="flex flex-cen">
        <div class="">
          <p class="mb20">您的支付订单已生成，请尽快付款！支付订单号：<span class="col-orange">{{ commandItem.pay_number }}</span></p>
          <p>请您在 <span class="col-orange">{{ commandItem.deadline }}</span> 前完成支付，否则需重新发起支付！</p>
        </div>
        <div class="">
          订单金额：<span class="col-orange">￥<font>{{ commandItem.amount }}</font> </span>
        </div>
      </div>
      <div class="flex align-cen flex-evenly">
        <div class="">
          <img :src="commandItem.qr_code" alt="" class="mb20">
          <div :class="{on:commandTxt == '微信'}" class="flex flex-cen">
            <img alt="" src="@/assets/img/personal/icon-sys.png">
            <div class="">
              <p class="mb10">请使用{{ commandTxt }}扫一扫</p>
              <p>扫描二维码支付</p>
            </div>
          </div>
        </div>
        <div class="flex align-cen">
          <img v-if="commandTxt == '支付宝'" alt="" src="@/assets/img/personal/zfbsys.png">
          <img v-else alt="" src="@/assets/img/personal/wxsys.png">
        </div>
      </div>

    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        class="downloadDia openDia"
        title="导出信息"
        width="576px"
        @close="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="editionDia"
        class="editionDia"
        title="提示"
        width="390px">
      <div class="text-cen flex align-cen"><i class="el-icon-warning m-r10"></i> 当前账号没有此操作权限，请升级至<span
          class="font-wbold col-red ">“企业版”</span>！
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="editionDia = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {applyBillDetail, qrCode,exportTms,exportCopyrights,exportLawsuits} from '@/api/personal.js'

const trademarkOptions = ['商标图样', '商标名称', '商标号', '商品类别', '申请人', '申请类型', '金额', '当前进度', '申请日期', '商品项目'];
const copyrightOptions = ['样品图片', '作品名称', '申请人', '作品类型', '金额', '当前进度', '发表日期', '完成日期', '报送日期', '作品性质'];
const lawsuitOptions = ['商标图样', '商标名称', '商标号', '商品类别', '原告/上诉人', '诉讼类型', '金额', '第三人', '当前进度', '判决结果', '创建日期', '商品项目'];
const trademarkFields = ['商标名称', '商标号', '商品类别', '申请人', '申请类型', '金额', '当前进度', '申请日期']
const copyrightFields = ['作品名称', '申请人', '作品类型', '金额', '当前进度', '报送日期']
const lawsuitFields = ['商标名称', '商标号', '商品类别', '原告/上诉人', '诉讼类型', '金额', '第三人', '当前进度', '判决结果', '创建日期']
export default {
  name: "BillDetail",
  data() {
    return {
      skeletonList: storage.getList(),
      loading: true,//控制整个页面的骨架屏

      id: '',
      detail: {},
      invoice: {
        digit:[],
      },
      copyrights: [],
      tms: [],
      lawsuits: [],
      trademark: true,
      copyright: true,
      lawsuit: true,
      commandSelect: [{id: 0, text: '微信'}, {id: 1, text: '支付宝'}],
      commandTxt: '',

      commandDia: false,
      commandItem: {},

      type: '',
      down: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      editionDia: false,
      checkAll: false,
      cities: '',
      isIndeterminate: true,
      submitDis: false,
      filters: {
        checkList: [],//记录满三年和可续展选中时的数组
        fields: '',
        bill_id:'',
      }

    }

  },
  created() {
    this.domesticDetails()
  },
  methods: {
    // 下载票据
    openUrl(item) {
      location.href = item.url
    },
    diaClick(item) {
      if (storage.getUser().type != 0) {
        this.downloadDia = true
        this.type = item
        if (item == 'trademark') {
          this.cities = trademarkOptions
          this.filters.fields = trademarkFields
        } else if (item == 'copyright') {
          this.cities = copyrightOptions
          this.filters.fields = copyrightFields
        } else {
          this.cities = lawsuitOptions
          this.filters.fields = lawsuitFields
        }
      } else {
        this.editionDia = true
      }


    },
    handleCheckAllChange(val) {
      this.filters.fields = val ? (this.type == 'trademark' ? trademarkOptions : (this.type == 'copyright' ? copyrightOptions : lawsuitOptions)) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    async download() {
      this.filters.bill_id = this.$route.params.id
      this.down = true
      this.downloadDia = false
      if (this.filters.fields.length != 0) {
        try {
          let res;
          if(this.type == 'trademark'){
            res = await exportTms(this.filters)
          }else if(this.type == 'copyright'){
            res = await exportCopyrights(this.filters)
          }else{
            res = await exportLawsuits(this.filters)
          }
          if(res && res.err_code === 0){
            location.href = res.data.url
            this.down = false
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        } finally {
          this.down = false
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }
    },

    async command(id) {
      let channel = this.commandTxt == '支付宝' ? 'alipay' : 'wechat';
      try {
        const res = await qrCode({id: id, channel: channel})
        if (res && res.err_code === 0) {
          this.commandDia = true
          res.data.qr_code = 'data:image/png;base64,' + res.data.qr_code
          this.commandItem = res.data
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }

      } finally {

      }

    },
    //详情数据
    async domesticDetails() {
      this.id = this.$route.params.id
      try {
        const res = await applyBillDetail({id: this.id})
        if (res && res.err_code === 0) {
          this.loading = false
          this.detail = res.data;
          this.invoice = res.data.invoice
          this.copyrights = res.data.copyrights
          this.tms = res.data.tms
          this.lawsuits = res.data.lawsuits ? res.data.lawsuits : []
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }
    },

    open(url) {
      location.href = url
    },
  },


}
</script>

<style lang="less" scoped>
@import "../../style/applyDetail.less";

.applyDetail-top {
  width: 100%;
  padding: 20px 40px;
  p{
    width: 49%;
  }
}


.applicationDetails-list {
  min-height: unset !important;
  padding-bottom: 40px !important;

  .list-t {
    padding-right: 20px;

    h3 {
      padding: 15px 20px;
      font-size: 18px;
      font-weight: bold;

      i {
        color: #3894FF;
        margin-right: 10px;
      }

      span {
        margin-left: 10px;
        font-size: 16px;
        color: #325A99;
      }
    }
  }

  .tmimage {
    .el-image {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border: 1px solid #DDD;
      background: #fff;

      ::v-deep img {
        max-height: 70px;
        max-width: 70px;
        min-width: unset;
        width: unset;
        height: unset;
        margin: 0;
      }
    }

  }
}

::v-deep .el-table {
  th,td{
    padding: 9px 0 !important;
  }
   .el-table__body-wrapper {
    max-height: 500px !important;
  }
}

.el-table.on {
  height: 0 !important;
}

::v-deep .downloadDia {
  .el-dialog__header {
    i {
      color: #3894FF;
      font-size: 30px;
    }
  }

  .el-dialog__body {

    font {
      font-size: 26px;
    }

    .flex-evenly {
      margin-top: 60px;

      img {
        width: 100%;
      }
    }

    .flex-evenly > div:nth-of-type(1) {
      width: 280px;

      .flex-cen {
        width: 100%;
        padding: 10px 30px;
        background: #01ADEF;

        img {
          width: 50px;
        }

        p {
          text-align: center;
          color: #fff;
          font-size: 16px;
        }
      }

      .flex-cen.on {
        background: #3DB035;
      }
    }

    .flex-evenly > div:nth-of-type(2) {
      width: 210px;
    }
  }
}

::v-deep .editionDia {
  .el-dialog {
    margin-top: 35vh !important;

    .el-dialog__body {
      padding: 10px 15px;
    }

    .el-dialog__footer {
      padding: 10px 20px;
    }
  }

  .el-icon-warning {
    font-size: 24px;
    color: #e6a23c;
  }
}
</style>
